import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { DynamicImage, LoadingPage, Seo } from 'components';
import Header from 'components/Header';
import EmailForm from './components/EmailForm';
import { mobile, useQuery } from 'styles/breakpoints';
import { createLead, updateQuizAnswers, updateUser } from 'state/user/effects';
import { AppState } from 'state/types';
import { useRouter } from 'apis/history';
import Text from 'components/Text';
import {
  useFirstVisitDate,
  usePageView,
  useQuizData,
  useTrackingQueryParams,
} from 'utils/hooks';
import { Events } from 'utils/events';
import BlackFridayBanner from '../../../components/BlackFridayBanner';
import Tracking from 'utils/tracking';
import { cliendCodeToUrl } from 'utils/cliendCodeToUrl';
import { normalizeStates } from 'utils/localization';

const Container = styled.div`
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  max-width: 50%;
  flex-direction: column;

  @media ${mobile} {
    max-width: calc(100% - 2rem);
  }
`;

const Title = styled(Text)`
  text-align: center;
  strong {
    font-family: inherit;
    font-weight: 700;
  }
  margin-bottom: 3rem;
`;

const Subtitle = styled(Text)`
  @media ${mobile} {
    text-align: center;
  }
`;

const Footer = styled(Text)`
  text-align: center;
  @media ${mobile} {
    margin-bottom: 1.5rem;
  }
`;

const ImageContainer = styled.div`
  margin: 4rem 0rem;
  width: 16rem;
  height: 16rem;

  @media ${mobile} {
    width: 7.5rem;
    height: 7.5rem;
  }
`;

const EmailFormStyled = styled(EmailForm)`
  margin-top: 1.5rem;
`;

const Email: FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const trackingParams = useTrackingQueryParams();
  const { isTablet } = useQuery();
  const { quiz_answers, user, geolocation, code, user_ip } = useSelector(
    (state: AppState) => state.user,
  );
  const config = useSelector((state: AppState) => state.config);
  const variant = config?.variant;
  const { countdownDiffInMS } = useFirstVisitDate();

  const quiz = new URLSearchParams(location.search).get('lp') ?? 'main';

  const dispatch = useDispatch();

  const { goToCheckout, goToResults } = useRouter();

  usePageView({
    city: encodeURIComponent(
      geolocation?.city?.toLowerCase().replace(/[^a-z0-9]/g, '') || '',
    ),
    country: geolocation?.iso_country?.toLowerCase(),
    state: normalizeStates(
      geolocation?.iso_country || '',
      geolocation?.iso_state || '',
    ),
    gender: quiz_answers?.gender ? quiz_answers?.gender[0] : null,
  });

  const handleNextRoute = () => {
    if (variant === 'email-first') {
      return location.search.includes('tst3')
        ? goToCheckout({ discount: false })
        : goToResults();
    }
    goToCheckout({ discount: false });
  };

  const handleGetResults = async (email: string) => {
    setError('');

    setLoading(true);

    Tracking.trackCTAButton(location.pathname);

    if (!user?.email || email !== user.email) {
      dispatch(dispatch(updateUser({ email, password: '' })));
    }

    quiz_answers.quiz = quiz;

    try {
      const code =
        (await dispatch(
          createLead({
            email,
            funnel: quiz,
            quiz_answers: { ...quiz_answers, ...trackingParams },
            language: quiz === 'ad5_es' ? 'es' : 'en',
          }),
        )) ?? '';

      // cliendCodeToUrl(code);
      dispatch(updateQuizAnswers(trackingParams));

      const userState = normalizeStates(
        geolocation?.iso_country || '',
        geolocation?.iso_state || '',
      );

      Tracking.leadCreated(
        code,
        email,
        geolocation?.iso_country,
        quiz_answers?.gender ? quiz_answers?.gender[0] : null,
        userState,
        user_ip,
      );
      Events.checkoutPageVisit(code);

      handleNextRoute();
    } catch (error) {
      setError(error?.response.data?.errors?.email ?? '');

      setLoading(false);
    }
  };

  const data = useQuizData('email');

  if (!data) {
    return <LoadingPage />;
  }

  return (
    <>
      <Seo />
      <Header logoVariant="left" />
      {data?.bfBanner ? (
        <BlackFridayBanner
          countdownDiffInMS={countdownDiffInMS}
          {...data.bfBanner}
        />
      ) : null}
      <Container>
        <ContentContainer>
          <Title
            type={isTablet ? 'h3400' : 'h1400'}
            color="dark100"
            dangerouslySetInnerHTML={{ __html: data?.title }}
          />
          <Subtitle type={isTablet ? 'bodyS' : 'body'} color="dark80">
            {data?.inputLabel}
          </Subtitle>
          <EmailFormStyled
            loading={loading}
            error={error}
            onSubmit={handleGetResults}
            continueBtnTitle={data?.continueBtnTitle}
            placeholder={data?.inputPlaceholder}
            showEmailSuggestion={data?.showEmailSuggestion}
          />
          <ImageContainer>
            <DynamicImage
              src={data.img}
              alt={data.alt}
              width="100%"
              height="100%"
            />
          </ImageContainer>

          <Footer type={isTablet ? 'bodyS' : 'body'} color="dark60">
            {data?.footer}
          </Footer>
        </ContentContainer>
      </Container>
    </>
  );
};

export default Email;
